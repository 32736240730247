/*
 * @Author: chenyixin
 * @LastEditors: chenyixin
 * @FilePath: \web-fabu\src\api\login.js
 */
import requireModel from "@/utils/http/request";

export function login(data) {
    return requireModel({
        url:"/api/ums/web/login",
        method:"post",
        data
    })
}
export function getCode() {
    return requireModel({
        url:"/api/ums/auth/captcha",
        method:"get",
    })
}

export function getlogoList(data) {
    return requireModel({
        url:"/api/ums/record/search",
        method:"post",
        data
    })
}

export function getDeptList(data) {
    return requireModel({
        url:"/api/ums/dept/search",
        method:"post",
        data
    })
}



