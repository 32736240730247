<template>
  <div class="login">
    <div class="title">新店助农后台管理系统</div>
    <div class="login-form">
      <div class="welcome"></div>
      <div class="form">
        <el-tabs v-model="tabChoice">
          <el-tab-pane label="账号登录" name="1">
            <el-form
              class="login-content"
              :model="ruleForm"
              :rules="rules"
              ref="loginForm"
              label-width="80px"
            >
            <div class="welcome"></div>
              <el-form-item label="账号" prop="account">
                <el-input
                  placeholder="请输入账号"
                  auto-complete="off"
                  v-model="ruleForm.account"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  show-password
                  placeholder="请输入密码"
                  auto-complete="off"
                  v-model="ruleForm.password"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="验证码" prop="verificationCode">
                <div class="code-line">
                    
                <el-input placeholder="请输入验证码" 
                  v-model="ruleForm.verificationCode"></el-input>
                
                <div class="login-code" @click="getCodeFn">
                    <img :src="codeForm.baseImg"  class="login-code-img"/>
                </div>
                </div>
              </el-form-item> -->
              <!-- <el-form-item>
                            <el-checkbox v-model="checked"></el-checkbox>
                            <span>记住我</span>
                        </el-form-item> -->
              <el-form-item>
                <el-button class="submit-btn" type="primary" @click="submitForm"
                  >立即登录</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- <el-tab-pane label="手机号登录" name="2">
            <div class="phone">
              <el-select style="width:100px" v-model="phone.numberBefore">
                <el-option
                  v-for="item in options"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input
                style="width:calc(100% - 110px)"
                v-model="phone.number"
              ></el-input>
            </div>
            <div class="phone">
              <el-input
                style="width: calc(100% - 120px);"
                v-model="phone.code"
              ></el-input>
              <el-button type="info">获取验证码</el-button>
            </div>
            <el-button type="primary">立即登录（即将开放）</el-button>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import { login } from "@/api/login";
export default {
  name: "LoginPage",
  created() {
    this.getCodeFn();
  },
  data() {
    return {
      tabChoice: "1",
      ruleForm: {
        account: "",
        password: "",
        verificationCode: "",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        // verificationCode: [
        // { required: true, message: "请输入验证码", trigger: "blur" },
        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        // ]
      },
      checked: false,
      phone: {
        numberBefore: "+86",
        number: "",
        code: "",
      },
      options: ["+86"],
      codeForm: {
        baseImg: "",
        key: null,
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm.password,'this.ruleForm.password')
          login({
            ...this.ruleForm,
            password: CryptoJS.MD5(this.ruleForm.password).toString(),
            loginMethodType: 2,
            loginPlatformType: 1
          })
            .then((res) => {
              window.localStorage.token = res.data.token;

              this.$router.push('/orderManage')
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          return false;
        }
      });
      // localStorage.setItem("login",true)
      // this.$router.push('/dashboard')
    },
    getCodeFn() {
      // getCode().then((res) => {
      //     console.log(res)
      //     this.codeForm = res.data
      // }).catch((e) =>{
      //     console.log(e)
      // })
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../../assets/img/login.jpg");
  background-size: cover;
  .title {
    width: auto;
    height: auto;font-family: cursive;
    // border: 1px solid red;
    position: absolute;
    left: 5%;
    top: 5%;
    font-size: 38px;
    font-weight: 700;
    color: #fff;
  }
  .login-form {
    width: 400px;
    min-height: 300px;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    // background-color: #fff;
    // border: 1px solid red;
    border-radius: 30px;
    // background-image: url('../../../assets/img/login-form.jpg');
    background:  #fff;
    color: #000;
    .form {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .welcome {
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0 50px;
      font-size: 20px;
      font-weight: 700;
    }
  }
  .submit-btn {
    width: 80%;
    float: left;
  }
  .phone {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #000;
  }
  ::v-deep .el-tabs__item {
    color: rgb(128, 223, 223);
  }
  ::v-deep .el-form-item__label {
    color: #000;
  }
  ::v-deep .el-input {
    float: left;
    max-width: 248px;
  }
  ::v-deep .el-input__inner {
    width: 100%;
    background-color: #fff;
    color: #000;
  }
  // display: flex;
  // border: 1px solid red;
}

.code-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  .login-code {
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-code-img {
    width: 100%;
  }
}
</style>
