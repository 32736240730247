/*
 * @Author: chenyixin
 * @LastEditors: chenyixin
 * @FilePath: \web-fabu\src\router\index.js
 */

import Login from '@/components/page/Login.vue';
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/components/layout/LayOut.vue"),
        children: [
            // {
            //     path:"/dashboard",
            //     component:()=>import("@/components/page/Dashboard"),
            //     meta:{
            //         title:"仪表盘"
            //     }
            // },
            {
                path: "/user",
                name: "user",
                component: () => import("@/components/page/systemManage/UserManage"),
                meta: {
                    title: "用户管理"
                }
            },
            // {
            //     path:"/department",
            //     name:"department",
            //     component:()=>import("@/components/page/systemManage/Department"),
            //     meta:{
            //         title:"部门管理"
            //     }
            // },
            // {
            //     path:"/role",
            //     name:"department",
            //     component:()=>import("@/components/page/systemManage/Role"),
            //     meta:{
            //         title:"角色管理"
            //     }
            // },
            // {
            //     path:"/menu",
            //     name:"department",
            //     component:()=>import("@/components/page/systemManage/Menu"),
            //     meta:{
            //         title:"菜单管理"
            //     }
            // },
            {
                path: "/orderManage",
                name: "orderManage",
                component: () => import("@/components/page/orderManage/index.vue"),
                meta: {
                    title: "工单管理"
                }
            },
            {
                path: "/map",
                name: "map",
                component: () => import("@/components/page/systemManage/Map"),
                meta: {
                    title: "站点管理"
                }
            },
            {
                path: "/checkTypeApply",
                name: "checkTypeApply",
                component: () => import("@/components/page/checkTypeApply"),
                meta: {
                    title: "身份切换申请"
                }
            },
            {
                path: "/orderApply",
                name: "orderApply",
                component: () => import("@/components/page/orderApply"),
                meta: {
                    title: "工单申请"
                }
            },
            {
                path: "/line",
                name: "line",
                component: () => import("@/components/page/systemManage/Map/line"),
                meta: {
                    title: "线路管理"
                }
            },
            {
                path: "/log",
                name: "log",
                component: () => import("@/components/page/systemManage/Log"),
                meta: {
                    title: "日志管理"
                }
            },
            {
                path: "/dept",
                name: "dept",
                component: () => import("@/components/page/systemManage/Dept"),
                meta: {
                    title: "部门管理"
                }
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            title: "登录"
        }
    },
    {
        path: '/404',
        name: 'PageNotFound',
        component: () => import("@/components/page/PageNotFound"),
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes
});

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(err => console.log(err))
}


router.beforeEach((to, from, next) => {
    if (to.path === from.path) {
        next(false)
        return
    }
    // console.log(localStorage.getItem("login"),to);
    next()
})
router.afterEach((to, from) => {
    console.log(to, from)
    // console.log(to,from);
    document.title = to.meta.title
});

export default router;